import { environment } from '@internal/environment/environment'
import { EFinancingLogo } from '@website-shared-library/machine/EFinancingLogo'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import { SetRequired } from 'type-fest'
import { TUnconfirmedMachineAttributesMap } from './TUnconfirmedMachineAttributesMap'
import { getUnconfirmedMachineAttributesMap } from './getUnconfirmedMachineAttributesMap'
import {
  FinancingCountriesMap,
  FinancingProvider,
  getLeasingQualification,
} from './leasing'

const sanitizeWheelDepth = (wheelDepth: number | undefined | null) => {
  if (typeof wheelDepth === 'number' && wheelDepth > 1) {
    return wheelDepth / 100
  }
  return wheelDepth
}

export enum MediaCategory {
  Standard = 'standard',
  Abnormality = 'abnormality',
}
export type MediaDTO = {
  DEWATERMARKED_URL?: string
  EFARM_WATERMARKED_URL?: string
  HASHES?: {
    AHASH: string
    DHASH: string
    PHASH: string
    WHASH: string
  }
  MEDIA_ATTRIBUTE?: string
  MEDIA_CATEGORY?: MediaCategory
  MEDIA_DATE?: string | Date
  MEDIA_DELETED?: boolean
  MEDIA_HEIGHT?: number
  MEDIA_ID?: string
  MEDIA_MIME_TYPE?: string
  MEDIA_SOURCE?: string
  MEDIA_URL?: string
  MEDIA_WIDTH?: number
  ORIGINAL_URL?: string
}

enum MachineDataSource {
  agmachinery = 'agmachinery',
  agriaffaires = 'agriaffaires',
  agriaffaires_fork = 'agriaffaires_fork',
  farmerapp = 'farmerapp',
  internal = 'internal',
  landwirt = 'landwirt',
  machinefinder = 'machinefinder',
  mascus = 'mascus',
  mia = 'mia',
  model_metadata = 'model_metadata',
  picasso = 'picasso',
  reap = 'reap',
  smarttrade = 'smarttrade',
  technikboerse = 'technikboerse',
  tractomarket = 'tractomarket',
  traktorpool = 'traktorpool',
  vin = 'vin',
  zoho = 'zoho',
}

export type MandatoryMachineData = {
  MACHINE_GROUP_ID: string
  MACHINE_TYPE: MachineType
}

export type OptionlMachineData = {
  ABNORMALITIES?: unknown[]
  AD_SCORE_REL?: number
  AD_SCORE?: number
  AD_URL?: string[]
  AUCTION?: boolean
  AVAILABLE_URLS?: string[]
  AVAILABLE?: boolean
  BALES_TOTAL_NUMBER?: number
  BASE_MODEL?: string | null
  BRAND?: string | null
  CONDITION?: string
  CURRENCY?: string
  DATE_OF_CREATION?: string
  DATE_OF_PUBLICATION_UPDATE?: string
  DATE_OF_PUBLICATION?: string
  DEALER_INFO?: {
    DEALER_STATUS: string
    LOCATION_COUNTRY?: string
    MASTER_ID: string
    NAME?: string
    POSTAL_CODE?: string
    STREET_ADDRESS?: string
    TOWN?: string
    ZOHO_ID?: string
  }
  DRUM_HOURS?: number
  E_FARM_CLASSIFIEDS?: unknown[]
  ENGINE_HOURS?: number
  ENGINE_HP?: number
  ENGINE_KW?: number
  FORCE_SYNC?: boolean
  FREE_TEXT_FIELD?: string | null
  FREE_TEXT_PARSED?: unknown
  ID?: string
  KEY_SOURCES?: Record<string, MachineDataSource>
  LOCATION_CITY?: string
  LOCATION_COUNTRY?: string
  MACHINE_AVAILABILITY_FOR_SALE_DATE?: string | null
  MACHINE_SERIAL_NUMBER?: string
  MAPPED_MODEL?: string | null
  MERGEABLE?: boolean
  MODEL_EXTENSION?: string | null
  MODEL_SERIES?: string | null
  MODEL?: string | null
  PRICE_EXPORT?: number
  PRICE_NET_EUR?: number
  PRICE_NET?: number
  PROCESSED_MEDIA?: MediaDTO[]
  RAW_MODEL?: string | null
  RAW_BRAND?: string | null
  REFERENCE?: string | null
  SHOP_SCORE_REL?: number
  SHOP_SCORE?: number
  SOURCE?: string[]
  SOURCES_INTERNAL_ID?: string
  SOURCES?: { SOURCE: MachineDataSource; SOURCES_INTERNAL_ID: string }[]
  WHEEL_FRONT_LEFT_DEPTH?: number | null
  WHEEL_FRONT_RIGHT_DEPTH?: number | null
  WHEEL_REAR_LEFT_DEPTH?: number | null
  WHEEL_REAR_RIGHT_DEPTH?: number | null
  YEAR_OF_PRODUCTION?: number
}

export type MachineData = MandatoryMachineData & OptionlMachineData

export type MachineDataAttribute = keyof MachineData

export enum MachineMediaSource {
  InspectionApp = 'mia', // MachineInspectionApp,
  SmartTrade = 'smarttrade',
  External = 'external',
}

export type MachineMedia = {
  url: string
  mime: string
  attribute?: string
  width?: number
  height?: number
  ratio?: number
  source: MachineMediaSource.External
}

export type Machine = {
  data: MachineData
  media: MachineMedia[]
  financing: FinancingCountriesMap
  machineGroupId: string
  brand: string
  name: string
  model: string
  machineType: MachineType
  yearOfProduction: number | null
  enginePower: number | null
  engineHours: number | null
  price: number | null
  previewImage: MachineMedia | null
  availability: boolean
  balesTotalNumber?: number | null
  drumHours?: number | null
  allowIndexing: boolean
  unconfirmedAttributes: TUnconfirmedMachineAttributesMap
  locationCountry: string | null
  pdp: boolean
}

export type MachineDTO = MachineData & {
  createdAt?: string
  searchScore: number
  APPLIED_RULES?: {
    pdp: boolean
  }
}

export type OldMachineDTO = {
  media?: MachineMedia[]
  dealerStatus?: string
  excludedCountriesForSerp?: string[]
  createdAt?: string
  updatedAt?: string
  brand?: string
  leasingQualified?: boolean
  leasingInfo?: {
    countryCode: TLocale
    logo: EFinancingLogo
    pmt: number
  } | null
  data: MachineData
}

const sanitizeData = (data: MachineData) => {
  // extract keys that we certainly don't need or don't want to expose
  const {
    ABNORMALITIES,
    AD_SCORE_REL,
    AD_SCORE,
    AD_URL,
    AUCTION,
    AVAILABLE_URLS,
    CONDITION,
    CURRENCY,
    DATE_OF_CREATION,
    DATE_OF_PUBLICATION_UPDATE,
    DATE_OF_PUBLICATION,
    E_FARM_CLASSIFIEDS,
    FREE_TEXT_FIELD,
    ENGINE_KW,
    FORCE_SYNC,
    FREE_TEXT_PARSED,
    ID,
    KEY_SOURCES,
    LOCATION_CITY,
    MACHINE_SERIAL_NUMBER,
    MERGEABLE,
    MODEL_EXTENSION,
    MODEL_SERIES,
    PRICE_EXPORT,
    PRICE_NET,
    PROCESSED_MEDIA,
    SHOP_SCORE_REL,
    SHOP_SCORE,
    SOURCE,
    SOURCES_INTERNAL_ID,
    SOURCES,
    DEALER_INFO,
    ...cleanData
  } = data

  return cleanData
}

export const reduceToMinimalMachine = (machine: Machine): Machine => ({
  ...machine,
  media: [],
  data: {
    MACHINE_GROUP_ID: machine.data.MACHINE_GROUP_ID,
    MACHINE_TYPE: machine.data.MACHINE_TYPE,
    MODEL: machine.data.MODEL ?? null,
    MAPPED_MODEL: machine.data.MAPPED_MODEL ?? null,
  },
  unconfirmedAttributes: {},
})

// cannot omitBy from lodash, because that crashes in  Edge Runtime 🤷‍♂️
const omitUndefined = <T extends Record<string, unknown>>(data: T) =>
  Object.entries(data).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

export const oldMachineDTOtoMachine = ({
  media,
  dealerStatus,
  excludedCountriesForSerp,
  createdAt,
  updatedAt,
  brand: rootBrand,
  leasingQualified,
  leasingInfo,
  data,
}: OldMachineDTO): Machine & { old: true } => {
  const brand = rootBrand ?? data.BRAND ?? data.RAW_BRAND ?? ''
  const model = data.MAPPED_MODEL ?? data.MODEL ?? data.RAW_MODEL ?? ''
  const previewImage = media?.[0] ?? null

  const sanitizedData: MachineData = {
    ...sanitizeData(data),
    WHEEL_FRONT_LEFT_DEPTH: sanitizeWheelDepth(data.WHEEL_FRONT_LEFT_DEPTH),
    WHEEL_FRONT_RIGHT_DEPTH: sanitizeWheelDepth(data.WHEEL_FRONT_RIGHT_DEPTH),
    WHEEL_REAR_LEFT_DEPTH: sanitizeWheelDepth(data.WHEEL_REAR_LEFT_DEPTH),
    WHEEL_REAR_RIGHT_DEPTH: sanitizeWheelDepth(data.WHEEL_REAR_RIGHT_DEPTH),
  }

  if (
    data.KEY_SOURCES?.['FREE_TEXT_FIELD'] === MachineDataSource.smarttrade &&
    data?.FREE_TEXT_FIELD
  ) {
    sanitizedData.FREE_TEXT_FIELD = data.FREE_TEXT_FIELD
  }
  const financing: FinancingCountriesMap = {}
  if (leasingQualified && leasingInfo) {
    financing[leasingInfo.countryCode.toUpperCase()] = {
      monthlyRate: leasingInfo.pmt,
      provider:
        leasingInfo.logo === 'dll'
          ? FinancingProvider.Dll
          : FinancingProvider.EFarm,
    }
  }

  return {
    data: omitUndefined(sanitizedData) as MachineData,
    media: media ?? [],
    financing,
    machineGroupId: data.MACHINE_GROUP_ID,
    brand: brand,
    model,
    name: `${brand ?? ''} ${model}`.trim(),
    machineType: data.MACHINE_TYPE,
    yearOfProduction: data.YEAR_OF_PRODUCTION ?? null,
    enginePower: data.ENGINE_HP ?? null,
    engineHours: data.ENGINE_HOURS ?? null,
    price: data.PRICE_NET_EUR ?? null,
    previewImage,
    availability: !!data.AVAILABLE,
    balesTotalNumber: data.BALES_TOTAL_NUMBER ?? null,
    drumHours: data.DRUM_HOURS ?? null,
    allowIndexing: dealerStatus === 'Onboarded' && !!previewImage,
    unconfirmedAttributes: getUnconfirmedMachineAttributesMap(data.KEY_SOURCES),
    locationCountry: data.LOCATION_COUNTRY ?? null,
    pdp: true,
    old: true,
  }
}

const mediaOrder = [
  'MEDIA_FRONT_LEFT_DIAGONAL',
  'MEDIA_FRONT',
  'MEDIA_FRONT_RIGHT_DIAGONAL',
  'MEDIA_SIDE_RIGHT',
  'MEDIA_REAR_RIGHT_DIAGONAL',
  'MEDIA_REAR',
  'MEDIA_REAR_LEFT_DIAGONAL',
  'MEDIA_BEHIND',
  'MEDIA_SIDE_LEFT',
  'MEDIA_CABIN_IN_DOOR',
  'MEDIA_DASHBOARD_STEERING_WHEEL',
  'MEDIA_HOURMETER',
  'MEDIA_SIDE_CONSOLE_ALL_CONTROLS',
  'MEDIA_SIDE_LEFT_RUNNING_ENGINE_OPENHATCH',
  'MEDIA_SIDE_RIGHT_RUNNING_ENGINE_OPENHATCH',
  'MEDIA_FRONT_AXIS',
  'MEDIA_ENGINE_START_AND_WALK_AROUND',
  'MEDIA_ENGINE_VBELT',
  'MEDIA_REAR_CONNECTIONS',
  'MEDIA_REAR_HITCH_SYSTEMS',
  'MEDIA_SHIFT_GATE_FOR_TRANSMISSION',
  'MEDIA_CHOPPER_CHAFF_SPREADER',
  'MEDIA_CHOPPING_UNIT',
  'MEDIA_CONTROL_ELEMENTS_ABOVE',
  'MEDIA_CONTROL_ELEMENTS_RIGHT',
  'MEDIA_CUTTING_UNIT_DRIVE_SIDE',
  'MEDIA_CUTTING_UNIT_FINGERS_AND_CUTTER',
  'MEDIA_FUNCTIONING_CUTTING_UNIT',
  'MEDIA_HEADER_HORIZONTAL',
  'MEDIA_MULTILINK_CUTTINGTOOL_CONNECTION',
  'MEDIA_ROTARY_DRUM_SIDE_OPENHATCH',
  'MEDIA_SIEVES',
  'MEDIA_SLOPE_CONVEYOR_CHAIN',
  'MEDIA_STRAW_WALKER_FROM_ABOVE',
  'MEDIA_WHEEL_FRONT_RIGHT_DIAGONAL',
  'MEDIA_WHEEL_FRONT_RIGHT_SIDE',
  'MEDIA_WHEEL_REAR_RIGHT_DIAGONAL',
  'MEDIA_WHEEL_REAR_RIGHT_SIDE',
  'MEDIA_WHEEL_REAR_LEFT_DIAGONAL',
  'MEDIA_WHEEL_REAR_LEFT_SIDE',
  'MEDIA_WHEEL_FRONT_LEFT_DIAGONAL',
  'MEDIA_WHEEL_FRONT_LEFT_SIDE',
  'MEDIA_ADDITIONAL',
].reduce<Record<string, number>>(
  (map, attribute, rank) => ({
    ...map,
    [attribute]: rank,
  }),
  {}
)

export const sortMedia = (media: MediaDTO[]) =>
  media.sort((mediaA, mediaB) => {
    if (!mediaA.MEDIA_ATTRIBUTE && !mediaB.MEDIA_ATTRIBUTE) {
      return 0
    }

    if (!mediaB.MEDIA_ATTRIBUTE) {
      return -1
    }

    if (!mediaA.MEDIA_ATTRIBUTE) {
      return 1
    }

    if (mediaA.MEDIA_ATTRIBUTE && mediaB.MEDIA_ATTRIBUTE) {
      const aRank = mediaOrder[mediaA.MEDIA_ATTRIBUTE]
      const bRank = mediaOrder[mediaB.MEDIA_ATTRIBUTE]
      if (aRank !== undefined && bRank !== undefined) {
        return aRank - bRank
      }
    }

    return 0
  })

const excludedMedia = ['MEDIA_CHASSIS_NUMBER_ON_FRAME', 'MEDIA_ENGINE_NUMBER']

export const processMachineMedia = (media: MediaDTO[]): MachineMedia[] =>
  sortMedia(media)
    .filter(
      (entry): entry is SetRequired<MediaDTO, 'MEDIA_URL'> =>
        entry.MEDIA_DELETED !== true &&
        !!entry.MEDIA_URL &&
        !(entry.MEDIA_MIME_TYPE ?? '').startsWith('video') &&
        entry.MEDIA_CATEGORY !== MediaCategory.Abnormality &&
        !excludedMedia.includes(entry.MEDIA_ATTRIBUTE ?? '')
    )
    .map((entry) => {
      let mime = entry.MEDIA_MIME_TYPE ?? ''
      let url = (entry.DEWATERMARKED_URL ?? entry.MEDIA_URL).replace(
        environment.MACHINE_MEDIA_ORIGINAL_CDN_URL!,
        ''
      )

      const machineMedia: MachineMedia = {
        url,
        mime,
        attribute: entry.MEDIA_ATTRIBUTE,
        width: entry.MEDIA_WIDTH,
        height: entry.MEDIA_HEIGHT,
        ratio:
          entry.MEDIA_WIDTH && entry.MEDIA_HEIGHT
            ? entry.MEDIA_WIDTH / entry.MEDIA_HEIGHT
            : undefined,
        source: MachineMediaSource.External,
      }

      return omitUndefined(machineMedia) as MachineMedia
    })

export const machineDTOtoMachine = (dto: MachineDTO): Machine => {
  const model = dto.MODEL ?? dto.BASE_MODEL ?? dto.RAW_MODEL ?? ''
  const brand = dto.BRAND ?? dto.RAW_BRAND ?? ''
  const media = processMachineMedia(dto.PROCESSED_MEDIA ?? [])
  const previewImage = media[0] ?? null

  const sanitizedData: MachineData = {
    ...sanitizeData({
      ...dto,
      MODEL: dto.RAW_MODEL,
      MAPPED_MODEL: dto.MODEL,
      BRAND: dto.BRAND ?? dto.RAW_BRAND,
    }),
    WHEEL_FRONT_LEFT_DEPTH: sanitizeWheelDepth(dto.WHEEL_FRONT_LEFT_DEPTH),
    WHEEL_FRONT_RIGHT_DEPTH: sanitizeWheelDepth(dto.WHEEL_FRONT_RIGHT_DEPTH),
    WHEEL_REAR_LEFT_DEPTH: sanitizeWheelDepth(dto.WHEEL_REAR_LEFT_DEPTH),
    WHEEL_REAR_RIGHT_DEPTH: sanitizeWheelDepth(dto.WHEEL_REAR_RIGHT_DEPTH),
  }

  if (
    dto.KEY_SOURCES?.['FREE_TEXT_FIELD'] === MachineDataSource.smarttrade &&
    dto?.FREE_TEXT_FIELD
  ) {
    sanitizedData.FREE_TEXT_FIELD = dto.FREE_TEXT_FIELD
  }
  const machine: Machine = {
    data: omitUndefined(sanitizedData) as MachineData,
    media,
    financing: {},
    machineGroupId: dto.MACHINE_GROUP_ID,
    brand,
    model,
    name: `${brand} ${model}`.trim(),
    machineType: dto.MACHINE_TYPE,
    yearOfProduction: dto.YEAR_OF_PRODUCTION ?? null,
    enginePower: dto.ENGINE_HP ?? null,
    engineHours: dto.ENGINE_HOURS ?? null,
    price: dto.PRICE_NET_EUR ?? null,
    previewImage,
    availability: !!dto.AVAILABLE,
    balesTotalNumber: dto.BALES_TOTAL_NUMBER ?? null,
    drumHours: dto.DRUM_HOURS ?? null,
    allowIndexing:
      dto.DEALER_INFO?.DEALER_STATUS === 'Onboarded' && !!previewImage,
    unconfirmedAttributes: getUnconfirmedMachineAttributesMap(dto.KEY_SOURCES),
    locationCountry: dto.LOCATION_COUNTRY ?? null,
    pdp: !!dto.APPLIED_RULES?.pdp,
  }

  machine.financing = getLeasingQualification(machine)

  return machine
}
